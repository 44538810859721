<template>
  <div></div>
</template>

<script>
export default {
  name:'lazada',
  data() {
    return {
      url:'https://auth.lazada.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=https://erp.6pl.com.cn/lazadaback&client_id=105430'
    }
  },
  created() {
    this.auto()
  },
  methods:{
    auto() {
      window.open(this.url, '_blank');
      // var tokens =  window.localStorage.getItem('token')
      // console.log(tokens);
    }
  }
}
</script>